export const API_URL = 'https://platform.renovai.com/api';
// * changing selectors - do not forget about .scss
export const APP_CLASSNAME = 'renovai-360';
export const APP_ALTERNATIVES_CLASSNAME = `${APP_CLASSNAME}-alternatives`;
export const APP_ALTERNATIVE_CLASSNAME = `${APP_CLASSNAME}-alternative`;
export const APP_INITIALIZED_CLASSNAME = 'initialized';

export const APP_SELECTOR = `.${APP_CLASSNAME}`;
export const APP_ALTERNATIVES_SELECTOR = `.${APP_ALTERNATIVES_CLASSNAME}`;
export const APP_ALTERNATIVE_SELECTOR = `.${APP_ALTERNATIVE_CLASSNAME}`;
export const APP_INITIALIZED_SELECTOR = `.${APP_INITIALIZED_CLASSNAME}`;
