/* eslint-disable no-undef */
import { APP_SELECTOR } from '../../src/constants.ts';
import './index.scss';

window.renovai360Change = (sku) => {
  const renovai360Element = document.querySelector(APP_SELECTOR);

  if (renovai360Element) {
    const wrap = renovai360Element.parentElement;

    wrap.style.height = `${wrap.getBoundingClientRect().height}px`;

    renovai360Element.remove();

    const renovai360Element2 = document.createElement('div');
    renovai360Element2.classList.add('renovai-360');
    renovai360Element2.dataset.sku = sku;

    wrap.appendChild(renovai360Element2);

    wrap.height = '';

    if (window.renovai360) {
      window.renovai360.init();
    }
  }
};

if (
  window.location.href.match('/clients/kuala') ||
  window.location.href.match('/clients/archbold')
) {
  const parent = window.self !== window.top ? window.parent : null;

  if (parent) {
    window.renovai360 = window.renovai360 || {};
    window.renovai360.notInitOnLoad = true;

    const GET_SKU = 'GET_SKU';

    const allowedOrigins = [
      'http://localhost',
      'https://test-platform.renovai.com',
      'http://test-platform.renovai.com',
    ];

    const originCheck = (origin) => {
      let status = false;

      for (let i = 0; i < allowedOrigins.length; i++) {
        const allowedOrigin = allowedOrigins[i];

        if (origin.startsWith(allowedOrigin)) {
          status = true;

          break;
        }
      }

      return status;
    };

    window.addEventListener('message', (event) => {
      if (originCheck(event.origin)) {
        if (event.data) {
          const { data } = event;
          const sku = data.sku || (data.skuWithType && data.skuWithType.sku);
          const vendor = window.location.href.split('/').at(-2);

          if (sku) {
            // SKU
            console.log('onSkuGet', sku);
            window.sku = sku;
            const gallery = document.querySelector('.insert-after-360');

            gallery.innerHTML = `<div class="renovai-360" data-sku="${sku}" data-vendor="${vendor}" data-size="700"></div>`;

            const alternatives = document.querySelector(
              '.insert-after-alternatives',
            );

            if (alternatives) {
              alternatives.innerHTML = `<div class="renovai-360-alternatives" data-sku="${sku}" data-vendor="${vendor}" data-size="700"></div>`;
            }

            if (window.renovai360.init && vendor !== 'kuala') {
              window.renovai360.init();
            } else {
              window.renovai360.notInitOnLoad = false;
            }
          }
        }
      }
    });

    parent.postMessage({ message: GET_SKU }, '*');
  }
}
